import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, Observable, of, tap } from 'rxjs'

const HOST = environment.HOST

const headers = environment.HttpHeaders
const httpOptions = { headers }

@Injectable({
	providedIn: 'root',
})
export class FinanceiroService {
	private static readonly QUERY_CONFIG_INSIGHTS: { [key: string]: { dateRangeQuery?: string; title: string; detailsRoute?: string } } = {
		retorno: {
			dateRangeQuery: `SELECT * FROM c WHERE CONTAINS(c.system, 'retorno-bancario') AND (c.date >= {start_date} AND c.date <= {end_date})`,
			title: 'Retorno Bancário',
			detailsRoute: 'financeiro/bancario/retorno',
		},
		remessa: {
			dateRangeQuery: `SELECT * FROM c WHERE CONTAINS(c.system, 'remessa-bancaria') AND (c.date >= {start_date} AND c.date <= {end_date})`,
			title: 'Remessa Bancário',
			detailsRoute: 'financeiro/bancario/remessa',
		},
	}

	constructor(private http: HttpClient) {}

	/**************************************************** COMPARTILHAMENTO DE VARIAVEIS LOCAIS **************************************/

	getQueryConfig(type: string): { dateRangeQuery?: string; title: string; detailsRoute?: string } {
		return FinanceiroService.QUERY_CONFIG_INSIGHTS[type]
	}

	/************************************************************** FIM *************************************************************/

	postArquivosRetorno(doc: any) {
		return this.http.post<any>(`${HOST}/financeiro/v1/arquivosretorno`, doc, httpOptions).pipe(
			tap((_) => console.log(`Response postArquivoRetorno`)),
			catchError(this.handleError<any>('postArquivoRetorno', null))
		)
	}

	postAtualizaStatusArquivo(body: object) {
		return this.http.post<any>(`${HOST}/financeiro/v1/atualizastatusarquivo`, body, httpOptions).pipe(
			tap((_) => console.log(`Response postAtualizaStatusArquivo`)),
			catchError(this.handleError<any>('postAtualizaStatusArquivo', null))
		)
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error)
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO: ${error.error}`)
				console.log(`RETORNO MESSAGE: ${error.error.message}`)
			}
			return of(error)
		}
	}
}
