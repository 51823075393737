import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { catchError, Observable, of, tap } from 'rxjs'
import { environment } from '../../../../environments/environment'

const HOST_INSIGHTS = environment.HOST_INSIGHTS
const KEY_INSIGHTS = environment.KEY_INSIGHTS
const HOST = environment.HOST
const PATH_COSMOS = environment.PATH_COSMOS

let typeRequest = ''


const headers =  environment.HttpHeaderInghts
const headersIntegracoes = environment.HttpHeaders
const httpOptions = { headers: headersIntegracoes }

export enum QueryType {
	Failures = 'failures',
	Performance = 'performance',
}

@Injectable({
	providedIn: 'root',
})
export class InsightService {
	private idsSource = new BehaviorSubject<number[]>([])
	currentIds = this.idsSource.asObservable()

	private dataSubject = new BehaviorSubject<any>(null)
	data$ = this.dataSubject.asObservable()

	private static readonly QUERY_CONFIG_INSIGHTS: { [key: string]: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string } } = {
		failures: {
			dateRangeQuery: `requests
        | where timestamp between(datetime({start_date}) .. datetime({end_date}))
        | where success == false
        | where operation_Name !in("GET /", "HEAD /")
        | where operation_Name !startswith "OPTIONS /"
          and operation_Name !startswith "GET /"
        | summarize call_IDs = make_list(itemId), call_Count = count() by operation_Name
        | where call_Count > 1
        | project operation_Name, call_IDs, call_Count
        | order by call_Count desc`,
			defaultQuery: `requests
        | where timestamp >= ago(1d)
        | where success == false
        | where operation_Name !in("GET /", "HEAD /")
        | where operation_Name !startswith "OPTIONS /"
          and operation_Name !startswith "GET /"
        | summarize call_IDs = make_list(itemId), call_Count = count() by operation_Name
        | where call_Count > 1
        | project operation_Name, call_IDs, call_Count
        | order by call_Count desc`,

			title: 'Falhas',
			detailsRoute: 'monitoring/insights/failures/details',
		},
		performance: {
			dateRangeQuery: `requests
        | where timestamp between(datetime({start_date}) .. datetime({end_date}))
        | where operation_Name !in("GET /", "HEAD /")
        | where operation_Name !startswith "OPTIONS /"
          and operation_Name !startswith "GET /"
        | summarize call_IDs = make_list(itemId), call_Count = count() by operation_Name
        | where call_Count > 1
        | project operation_Name, call_IDs, call_Count
        | order by call_Count desc`,
			defaultQuery: `requests
        | where timestamp >= ago(1d)
        | where operation_Name !in("GET /", "HEAD /")
        | where operation_Name !startswith "OPTIONS /"
          and operation_Name !startswith "GET /"
        | summarize call_IDs = make_list(itemId), call_Count = count() by operation_Name
        | where call_Count > 1
        | project operation_Name, call_IDs, call_Count
        | order by call_Count desc`,
			title: 'Performance',
			detailsRoute: 'monitoring/insights/performance/details',
		},
		operationNames: {
			defaultQuery: `requests
        | where operation_Name !in("GET /", "HEAD /")
        | where operation_Name !startswith "OPTIONS /"
          and operation_Name !startswith "GET /"
          and operation_Name !startswith "POST /"
          and operation_Name !startswith "HEAD /"
          and operation_Name !startswith "PUT /"
          and operation_Name !startswith "DELETE /"
          and operation_Name !startswith "PATCH /"
        | distinct operation_Name`,
			title: 'Nomes das Operações',
		},
		operationDetails: {
			defaultQuery: `requests
        | where operation_Name !in("GET /", "HEAD /")
        | where operation_Name !startswith "OPTIONS /"
          and operation_Name !startswith "GET /"
        | where itemId in ({idsString})
        | order by timestamp desc`,
			title: 'Nomes das Operações',
		},
		alerts: {
			defaultQuery: `requests
        | where timestamp >= ago(5m)
          and operation_Name contains "name"
        | summarize
        call_Count = count(),
        error_Count = countif(success == false) by operation_Name`,
			title: 'Nomes das Operações',
		},
	}

	constructor(private http: HttpClient) {}

	/**************************************************** COMPARTILHAMENTO DE VARIAVEIS LOCAIS **************************************/

	updateIds(ids: any): void {
		this.idsSource.next(ids)
	}

	setData(data: any): void {
		this.dataSubject.next(data)
	}

	getData(): any {
		return this.dataSubject.getValue()
	}

	getQueryConfig(type: string): { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string } {
		console.log(type, 'type service')
		return InsightService.QUERY_CONFIG_INSIGHTS[type]
	}

	/************************************************************** FIM *************************************************************/

  /***************************************************** FORMATA O JSON DE RETORNO ************************************************/

	mapInsightsDataToObjects(data: any): any[] {
		if (!data || data.length === 0 || !data[0].rows || !data[0].columns) {
			console.error('Dados inesperados no mapeamento de insights.')
			return []
		}

		return data[0].rows.map((row: any) => {
			const insight: { [key: string]: any } = {}

			data[0].columns.forEach((column: any, index: number) => {
				let value = row[index]

				if (column.type === 'dynamic' && typeof value === 'string') {
					try {
						value = JSON.parse(value)
					} catch (e) {
						console.error('Erro ao parsear valor dinâmico:', e)
					}
				}

				insight[column.name] = value
			})

			return insight
		})
	}

  /************************************************************** FIM *************************************************************/

	/********************************************************** SERVIÇO APP INSIGHTS ************************************************/

	executeQuery(query: any): Observable<any> {
    typeRequest = 'app-insights'
		return this.http.post<any>(`${HOST_INSIGHTS}/${KEY_INSIGHTS}/query`, query, { headers }).pipe(
			tap(() => console.log('Query executed successfully')),
			catchError(this.handleError<any>('executeQuery', null))
		)
	}

	/************************************************************** FIM *************************************************************/
  /********************************************************** SERVIÇO INTEGRACOES ************************************************/




	/************************************************************** FIM *************************************************************/

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
