import { Component } from '@angular/core'
import { InsightService } from './insight.service'

@Component({
	selector: 'app-insight',
	templateUrl: './insight.component.html',
	styleUrl: './insight.component.scss',
})
export class InsightComponent {
	title = 'INSIGHT'
}
