import { RegistroCosmosService } from './../../../../shared/registro-cosmos.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { InsightService } from './../insight.service'
import { Component, OnInit, TemplateRef } from '@angular/core'

interface OperationObject {
	operation_Name: string
	formatted_Name?: string // Propriedade opcional
	operation: string
}

@Component({
	selector: 'app-alerts-insight',
	templateUrl: './alerts-insight.component.html',
	styleUrl: './alerts-insight.component.scss',
})
export class AlertsInsightComponent implements OnInit {
	private queryConfigOperationNames: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string }
	private queryAlerts: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string }
	selectedOperationName: string = 'Operações'
	operationsFormated: any
	operationsResponse: any
	callAndErrorResponse: any

	typeRegisterCosmos: string = 'hermesAlertsQuery'
	percentageValue: number = 0

	//Modal
	modalRef: NgbModalRef
	closeResult: string = ''

	constructor(private insightService: InsightService, private modalService: NgbModal, private registroCosmosService: RegistroCosmosService) {}

	ngOnInit(): void {
		this.queryConfigOperationNames = this.insightService.getQueryConfig('operationNames')
		this.queryAlerts = this.insightService.getQueryConfig('alerts')
		if (this.queryConfigOperationNames) {
			this.readOperationNames()
		}
	}

	onChangeOperationName(value: string) {
		console.log(value, 'operacao')
		this.selectedOperationName = value
	}

	readOperationNames() {
		let query = {}

		query = this.queryConfigOperationNames.defaultQuery
		this.insightService.executeQuery({ query: query }).subscribe({
			next: (result) => {
				this.operationsResponse = this.insightService.mapInsightsDataToObjects(result.tables)
				this.operationsFormated = this.updateOperationNames(this.operationsResponse)
				console.log(this.operationsFormated, 'response')
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	insertQueryCosmos() {
		const today = new Date()
		const formattedDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0')

		let query = {}
		query = this.queryAlerts.defaultQuery.replace('name', this.selectedOperationName)

		const registroHermesQuery = {
			type: this.typeRegisterCosmos,
			query: query,
			percentage: this.percentageValue,
		}

    console.log(registroHermesQuery, 'registro')

		this.registroCosmosService.createRegisterCosmos(registroHermesQuery).subscribe({
			next: (result) => {
				console.log(result)
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	readCallAndErrorSummaryByOperation() {
		let query = {}

		query = this.queryAlerts.defaultQuery.replace('name', this.selectedOperationName)
		console.log(query, 'query')

		this.insightService.executeQuery({ query: query }).subscribe({
			next: (result) => {
				console.log(result)
				this.callAndErrorResponse = this.insightService.mapInsightsDataToObjects(result.tables)
				console.log(this.callAndErrorResponse, 'response alerts')
			},
			error: (error) => {
				console.error(error)
			},
		})
	}

	updateOperationNames(objects: OperationObject[]): OperationObject[] {
		const replacements: { [key: string]: string } = {
			'59e110b1b5857a0f6062658f': 'atendimento',
			'5968c2f5b5857a0e8857fdf5': 'beneficio',
			'59b6e009b5857a12cce81978': 'controleInterno',
			'5a1d694c058ad5247df22b94': 'corporativo',
			'5a1d694c058ad5247df22b94-v2': 'corporativo',
			'59e1a063b5857a16e8cf723f': 'emprestimo',
			'5a7a19b9712cec3e64da28f5': 'INT_Legados',
			'5a1f5f8069c45e24d073fb09': 'seguridade',
			'59b6bdf8b5857a12cce8196e': 'capitalizacao',
			'59233208b0d21217885942dd': 'autoPatrocinio',
		}

		// Mapeia os objetos e atualiza a propriedade formatted_Name
		const updatedObjects = objects
			.map((obj) => {
				const parts = obj.operation_Name.split(' - ')

				if (parts.length > 1) {
					const beforeSemicolon = parts[0].split(';')[0].trim()
					const afterDash = parts[1].trim()

					const updatedBeforeSemicolon = replacements[beforeSemicolon] || beforeSemicolon

					// Cria uma nova propriedade com o nome formatado
					obj.formatted_Name = `${updatedBeforeSemicolon} - ${afterDash}`
					obj.operation = afterDash
				} else {
					console.warn(`Formato inválido: ${obj.operation_Name}`)
					// A nova propriedade será igual ao valor original em caso de formato inválido
					obj.formatted_Name = obj.operation_Name
				}

				return obj
			})
			.sort((a, b) => (a.formatted_Name || '').localeCompare(b.formatted_Name || '')) // Ordena por formatted_Name

		// Remove objetos com formatted_Name iguais
		const uniqueNames = new Set<string>()
		const uniqueObjects = updatedObjects.filter((obj) => {
			if (uniqueNames.has(obj.formatted_Name || '')) {
				return false
			} else {
				uniqueNames.add(obj.formatted_Name || '')
				return true
			}
		})

		return uniqueObjects
	}

	cleanInputForm() {
		this.selectedOperationName = 'Operações'
	}

	openModal(form: TemplateRef<any>) {
		this.modalRef = this.modalService.open(form, { size: 'xl', scrollable: true, centered: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss() {
		this.modalRef.dismiss()
		//this.cleanAndReload()
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
