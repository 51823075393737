<div class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-3" style="text-align: center">
		<h2 class="text-primary p-0 m-0">{{ title }} </h2>
	</div>
	<div></div>
	<div class="card-header card-header-customer py-4 px-2 d-flex justify-content-center align-items-center">
		<form class="row row-cols-sm-auto d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="dp-hidden position-absolute">
					<div class="input-group">
						<input
							name="datepicker"
							class="form-control"
							ngbDatepicker
							#datepicker="ngbDatepicker"
							[autoClose]="'outside'"
							(dateSelect)="onDateSelection($event)"
							[displayMonths]="2"
							navigation="none"
							[dayTemplate]="t"
							outsideDays="hidden"
							[startDate]="fromDate!"
							tabindex="-1"
							[(ngModel)]="model"
						/>
						<ng-template #t let-date let-focused="focused">
							<span
								class="custom-day"
								[class.focused]="focused"
								[class.range]="isRange(date)"
								[class.faded]="isHovered(date) || isInside(date)"
								(mouseenter)="hoveredDate = date"
								(mouseleave)="hoveredDate = null"
							>
								{{ date.day }}
							</span>
						</ng-template>
					</div>
				</div>
				<div class="col-12">
					<div class="input-group">
						<input
							#dpFromDate
							class="form-control"
							placeholder="yyyy-mm-dd"
							name="dpFromDate"
							[value]="formatter.format(fromDate)"
							(input)="fromDate = validateInput(fromDate, dpFromDate.value)"
						/>
						<button class="btn btn-input-customer" id="button-addon2" (click)="datepicker.toggle()" type="button">
							<em i-bs name="calendar-range"></em>
						</button>
					</div>
					<div class="custom-alert" role="alert" *ngIf="requiredMessage && fromDate === null" style="margin-top: 5px">
						{{ requiredMessage }}
					</div>
				</div>
			</div>

			<div class="col-12">
				<div class="input-group">
					<input
						#dpToDate
						class="form-control"
						placeholder="yyyy-mm-dd"
						name="dpToDate"
						[value]="formatter.format(toDate)"
						(input)="toDate = validateInput(toDate, dpToDate.value)"
					/>
					<button class="btn btn-input-customer" (click)="datepicker.toggle()" type="button">
						<em i-bs name="calendar-range"></em>
					</button>
				</div>
				<div class="custom-alert" role="alert" *ngIf="requiredMessage && toDate === null" style="margin-top: 5px">
					{{ requiredMessage }}
				</div>
			</div>

			<!-- <div class="col-12 px=0" style="width: 16rem">
				<div class="form-group">
					<select
						[ngModel]="selectedTypeFiles"
						class="form-control form-select border-opacity-50 p-2"
						(ngModelChange)="onChangeFiles($event)"
						name="selectedTypeFiles"
						required
					>
						<option [selected]="true" disabled>Arquivos</option>
						<option *ngFor="let file of filesFilter" [value]="file">{{ file }}</option>
					</select>
					<div class="custom-alert" role="alert" *ngIf="requiredMessage && selectedTypeFiles === 'Arquivos'" style="margin-top: 5px; color: transparent">
						{{ requiredMessage }}
					</div>
				</div>
			</div>

			<div class="col-12 px=0" style="width: 16rem">
				<div class="form-group">
					<select
						[ngModel]="selectedCompanies"
						class="form-control form-select border-opacity-50 p-2"
						(ngModelChange)="onChangeCompanies($event)"
						name="selectedCompanies"
						required
					>
						<option [selected]="true" disabled>Empresas</option>
						<option *ngFor="let company of companiesFilter" [value]="company">{{ company }}</option>
					</select>
					<div class="custom-alert" role="alert" *ngIf="requiredMessage && selectedCompanies === 'Empresas'" style="margin-top: 5px; color: transparent">
						{{ requiredMessage }}
					</div>
				</div>
			</div> -->
			<div class="input-group search w-25">
				<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" style="height: 2.5rem"/>
			</div>

			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="requiredVerification()">
					<em i-bs name="search"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger" (click)="cleanAndReload()">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>
	<div class="card-body h-auto m-0 p-0">
		<div class="table-responsive">
			<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 1">
				<strong>{{ infoMessage.message }}</strong>
			</div>
			<div class="d-flex justify-content-center align-items-center py-5" *ngIf="infoMessage.status == 2">
				<div class="spinner-border text-primary" role="status"></div>
			</div>
			<table class="table table-sm table-hover align-middle" *ngIf="records && infoMessage.status == 3">
        <caption style="display: none;">Tabela mostra informações do envio de arquivo PTA.</caption>
				<thead class="table-light">
					<tr>
						<th scope="col" class="align-middle">Nome Arquivo</th>
						<th scope="col" class="align-middle">Data</th>
						<th scope="col" class="text-center align-middle">Usuário</th>
						<th scope="col" class="text-center align-middle">Grupo</th>
						<th scope="col" *ngIf="profile_hermes_tecnico" class="text-center align-middle">Detalhes</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let record of pagefiltered">
						<td class="align-middle">{{ record.nomeArquivoEnviado }}</td>
						<td class="align-middle">{{ record.dataHora }}</td>
						<td class="text-center align-middle">{{ record.usuario }}</td>
						<td class="text-center align-middle">{{ record.nomeGrupo | uppercase}}</td>
						<td *ngIf="profile_hermes_tecnico" class="text-center align-middle" style="width: 8rem">
							<button type="button" class="btn btn-customer-info" (click)="detailModal(record, modalDetail)">
								<em i-bs name="justify" width="20" height="20"></em>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
		<ngb-pagination
			class="pagination-customer"
			[collectionSize]="collectionSize"
			[(page)]="page"
			[pageSize]="pageSize"
			(pageChange)="refreshPage()"
		></ngb-pagination>

		<select class="pagination-select-customer" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshPage()">
			<option [ngValue]="20">20 items por página</option>
			<option [ngValue]="30">30 items por página</option>
			<option [ngValue]="40">40 items por página</option>
		</select>
	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="onDismiss()"></button>
	</div>
	<div class="modal-body">
		<table class="table table-sm table-hover align-middle" *ngIf="recordCheckFilesCount != 0 || recordCriticizeCount != 0 || recordPTAxFaseCount != 0 || recordPTAxEmprestimoCount != 0">
			<thead class="table-light">
				<tr>
          <th class="align-middle">Tipo</th>
					<th class="align-middle">Nome Arquivo</th>
					<th class="align-middle">Data</th>
					<th class="text-center align-middle">Status</th>
					<th class="text-center align-middle">Qt Linhas</th>
				</tr>
			</thead>
			<tbody *ngIf="recordCheckFilesCount != 0">
				<tr *ngFor="let record of recordCheckFiles">
          <td class="align-middle">{{ record.tipo }}</td>
					<td class="align-middle">{{ record.nomeArquivoEnviado }}</td>
					<td class="align-middle">{{ record.dataHora | date : 'dd-MM-yyyy HH:mm:ss' }}</td>
					<td class="text-center align-middle">
						<em [style.color]="record.status == 'Sucesso' ? 'green' : 'red'" i-bs name="circle-fill" width="8" height="8"></em>
					</td>
					<td class="text-center align-middle">{{ record.qtdLinhasDiferentes }} - diferença(s)</td>
				</tr>
			</tbody>
      <tbody *ngIf="recordCriticizeCount != 0">
				<tr *ngFor="let record of recordCriticize">
          <td class="align-middle">{{ record.tipo }}</td>
					<td class="align-middle">{{ nameFileFormatedTable(record.nomeArquivo)  }}</td>
					<td class="align-middle">{{ record.dataHora }}</td>
					<td class="text-center align-middle">
						<em [style.color]="record.status == 'sucesso' ? 'green' : 'red'" i-bs name="circle-fill" width="8" height="8"></em>
					</td>
					<td class="text-center align-middle">{{ record.qtdLinhasCriticas }} - criticada(s)</td>
				</tr>
			</tbody>
      <tbody *ngIf="recordPTAxFaseCount != 0">
				<tr *ngFor="let record of recordPTAxFase">
          <td class="align-middle">{{ record.tipo }}</td>
					<td class="align-middle">{{ nameFileFormatedTable(record.nomeArquivo)  }}</td>
					<td class="align-middle">{{ record.data }}</td>
					<td class="text-center align-middle">
						<em [style.color]="record.status == 'Sucesso' ? 'green' : 'red'" i-bs name="circle-fill" width="8" height="8"></em>
					</td>
					<td class="text-center align-middle">{{ record.qtLinhasEnviadas }} - enviada(s)</td>
				</tr>
			</tbody>
      <tbody *ngIf="recordPTAxEmprestimoCount != 0">
				<tr *ngFor="let record of recordPTAxEmprestimo">
          <td class="align-middle">{{ record.tipo }}</td>
					<td class="align-middle">{{ nameFileFormatedTable(record.nomeArquivo)  }}</td>
					<td class="align-middle">{{ record.dataHora }}</td>
					<td class="text-center align-middle">
						<em [style.color]="record.status == 'sucesso'  ? 'green' : 'red'" i-bs name="circle-fill" width="8" height="8"></em>
					</td>
					<!-- <td class="text-center align-middle">{{ record.qtLinhasEnviadas }} - enviada(s)</td> -->
				</tr>
			</tbody>
		</table>
    <div *ngIf="recordCheckFilesCount == 0 && recordCriticizeCount == 0 && recordPTAxFaseCount == 0 && recordPTAxEmprestimoCount == 0">
      <p>Não foram encontrados registros de logs para esse arquivo!</p>
    </div>
	</div>
</ng-template>
