import { Component, OnInit } from '@angular/core'
import { EChartsOption } from 'echarts'
import { InsightService } from '../insight.service'

interface Metrics {
	timestamp: string
	count_: number
}

@Component({
	selector: 'app-metrics',
	templateUrl: './metrics.component.html',
	styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit {
	metricsSuccess: Metrics[] = []
	countMetrics: number[] = []
	timestampMetrics: string[] = []
	chartOptionFailures: EChartsOption = {}
	chartOptionSuccess: EChartsOption = {}

	constructor(private insightsService: InsightService) {}

	ngOnInit(): void {
		this.fetchMetrics('200', 'chartOptionFailures', '#b91717')
		this.fetchMetrics('false', 'chartOptionSuccess')
	}

	fetchMetrics(resultCode: string, chartOptionKey: 'chartOptionFailures' | 'chartOptionSuccess', color?: string): void {
		const query = {
			query: `requests
        | where timestamp >= ago(1d) ${resultCode === '200' ? "and resultCode == '200'" : 'and success == false'}
        | summarize count() by bin(timestamp, 1h)`,
		}
		console.log(query, 'query')

		this.insightsService.executeQuery(query).subscribe({
			next: (result) => {
				this.metricsSuccess = this.parseMetrics(result.tables)
				this.updateChart(chartOptionKey, color)
			},
			error: (error) => {
				console.error('Error fetching data', error)
			},
		})
	}

	parseMetrics(data: any): Metrics[] {
		return data[0].rows.map((row: any) => {
			const obj: { [key: string]: any } = {}
			data[0].columns.forEach((column: any, index: any) => {
				obj[column.name] = row[index]
			})
			return obj as Metrics
		})
	}

	updateChart(chartOptionKey: 'chartOptionFailures' | 'chartOptionSuccess', color: string = '#5470C6'): void {
		this.countMetrics = this.metricsSuccess.map((item) => item.count_)
		this.timestampMetrics = this.metricsSuccess.map((item) => item.timestamp.split('T')[1].split('Z')[0])

		this[chartOptionKey] = {
			color: [color],
			xAxis: {
				type: 'category',
				data: this.timestampMetrics,
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					data: this.countMetrics,
					type: 'line',
				},
			],
		}
	}
}
