import { LogLevel } from '@azure/msal-browser'

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1

export const environment = {
  production: false,
  descricaoAmbiente: 'Desenvolvimento',

  HOST: 'https://azbrsp1apih001.azure-api.net',
	PATH_PTA: 'pta/v1',
	PATH_BLOB: 'blob/v1',
	PATH_EMAIL: 'email/v1',
	PATH_COSMOS: 'docs-bd/v1/bds',
	PATH_COSMOS_SDK: 'docs-bd/v1/consultas/bds',
  PATH_APIM: 'apimanagement/v1/hermes',

  HOST_INSIGHTS: 'https://api.applicationinsights.io/v1/apps',
  KEY_INSIGHTS: 'f3d136ab-53b1-4b24-9dbb-6f8c72653186', // id encontrado no aplication insigths em API Access
  KEY_INSIGHTS_HERMES: 'kxgbjseamy83ay1ylhj3frcxcw2y2sqhr1gjawgl', // api Key crianda em API Access para o hermes

  HttpHeaders: {
		'Content-Type': 'application/json',
		'Cache-Control': 'no-cache',
		'anti-csrf-token': 'csrf_token',
		Pragma: 'no-cache',
		'Ocp-Apim-Subscription-Key': 'b323eb6736e3403bb0bdffa56e166b4c',
	},

  HttpHeaderInghts: {
    'Content-Type': 'application/json',
    'x-api-key': 'kxgbjseamy83ay1ylhj3frcxcw2y2sqhr1gjawgl'
  },

  message: {
		id: 'b1103bc5-e87d-4daf-bc8e-b613d504c1ea',
	},

  perfisUserHermes: {
		hermes_tecnico: 'b8a4394c-d248-4f3c-bb68-494c827e9b68', // Tamara
		hermes_cadastro: 'ab7b68ef-f1fc-47ba-b37a-608984dc4c27',
    hermes_pta: 'd2ec25f2-47ff-40b5-aea8-310fa5d3feb1',
    hermes_financeiro: '13460cdd-59a9-46a8-8020-f423c6275a39'
  },
  perfisUserPTA: {
    pta_cadastro: '507d9a57-5163-4d92-926a-e61faa3b795f',
		pta_arrecadacao: 'fd5b9915-3ee1-46b9-b944-4fd9798968ed',
		pta_emprestimo: '4fd8cbfd-5fb0-499e-a3e8-470114987947',
    pta_consignataria: '23c3bfd8-4ef8-46d1-9d93-1284cd183230',
  }
};

export const msalConfig = {
  auth: {
    clientId: '9c70b011-e1b5-48ac-be56-9bc8c48644d0',
    authority: 'https://login.microsoftonline.com/valiaweb.net.br',
    redirectUri: 'https://hermes-dev.valia.com.br/',
    //redirectUri: 'https://localhost:4200/',
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback(LogLevel: any, message: any, containsPii: any) {
        console.log(message)
      },
      logLevel: LogLevel.Verbose,
    },
  },
}
export const protectedResources = {
  endpoint: 'https://graph.microsoft.com/v1.0/me',
  scopes: ['user.read'],
}
