import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, of, tap } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class DataLakeService {
	constructor(private http: HttpClient) {}

	getFolders(destiny: string, parameters: string[], pageSize: number, continuationToken: string | null): Observable<any> {
		const body = parameters
		const headers = {
			'X-Continuation-Token': continuationToken || '',
			'X-Page-Size': pageSize.toString(),
		}

		return this.http.post<any>(`${destiny}/folder`, body, { headers }).pipe(
			tap((_) => console.log(`Response List Folders`)),
			catchError(this.handleError<any>('List Folders', null))
		)
	}

  getFoldersSignin(destiny: string, parameters: string[]): Observable<any> {
		const queryParameters = parameters.map((path) => `parameters=${path}`).join('&')
		return this.http.get<any>(`insights/folder?${queryParameters}`).pipe(
			tap((_) => console.log(`Response List Folders`)),
			catchError(this.handleError<any>('List Folders', null))
		)
	}


	getFiles(destiny: string, filePaths: string[], email?: string, id?: string): Observable<any> {
		console.log(destiny, 'destino')
		const body = filePaths
		const params: any = {}

		if (email) {
			params.email = email
		}
		if (id) {
			params.id = id
		}

		return this.http.post<any>(`${destiny}/files`, body, { params })
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			if (error.error instanceof ErrorEvent) {
				console.error(error) // log to console instead
				console.log(`${operation} failed: ${error.message}`)
				console.log(`RETORNO STATUS: ${error.status}`)
				console.log(`RETORNO ERRO ERRO: ${error.error}`)
				console.error('RETORNO ERROR ERROR MESSAGE:', error.error.message)
			}
			return of(error)
		}
	}
}
