<div class="card stretch-card">
	<nav class="navbar navbar-expand-lg navbar-light bg-light m-0 p-0">
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/monitoring/insights/performance']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						>PERFORMANCE</a
					>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/monitoring/insights/failures']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						>FAILURES
					</a>
				</li>
        <!-- <li class="nav-item">
					<a
						class="nav-link"
						[routerLink]="['/monitoring/insights/alerts']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{ exact: true }"
						>ALERTS
					</a>
				</li> -->
			</ul>
		</div>
	</nav>
	<div class="card-body">
		<router-outlet></router-outlet>
	</div>
</div>
