import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { FileApim } from '../../../../models/file-apim.model'
import { FilePath } from '../../../../models/filepath.model'

import { DataLakeService } from '../data-lake.service'
import { NotificationService } from './../../../../shared/notification.service'

@Component({
	selector: 'app-apim-data-lake',
	templateUrl: './apim-data-lake.component.html',
	styleUrl: './apim-data-lake.component.scss',
})
export class ApimDataLakeComponent implements OnInit {
	dynamicUrl = 'monitoring/insights/data/apim'
	filesData: any[][] = []
	folderPaths: any[] = []

	selectedFileDetails!: any
	contentType: string
	modalDetails: any

	notification = { status: 0, message: '' }

	// Search
	searchDate: any | null = null
	searchTerm: string = ''
	filteredFiles: any[][] = []
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Modal
	modalReference!: NgbModalRef
	modalCloseResult: string = ''
	size: string

	// Paginação
	page = 1
	pageSize = 50
	paginatedFile: any[] = []

	collectionSize: number = 0

	continuationToken: string | null = null
	loadingMore: boolean = false

	currentIndex: number = 0 // Índice da página atual

	constructor(
		private dataLakeService: DataLakeService,
		private notificationService: NotificationService,
		private cdr: ChangeDetectorRef,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this.loadFolders(this.page)
	}

	padZero(number: number): string {
		return number <= 9 ? '0' + number : number.toString()
	}

	// set searchTermValue(value: string) {
	// 	this.searchTerm = value
	// 	this.filteredFiles = this.searchTerm ? this.filterFile(this.searchTerm) : this.filesData
	// 	this.collectionSize = this.filteredFiles.length
	// 	this.paginateFileApim()
	// }

	// filterFile(term: string): FileApim[] {
	// 	const lowerCaseTerm = term.toLowerCase()

	// 	return this.filesData.filter((file) => {
	// 		return file.operationName.toLowerCase().includes(lowerCaseTerm)
	// 	})
	// }

	// paginateFileApim() {
	// 	this.paginatedFile = this.filteredFiles
	// 		.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
	// 		.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)

	// 	this.paginatedFile = [...this.paginatedFile]

	// 	console.log(this.paginatedFile, 'aqui')

	// 	this.cdr.detectChanges()
	// }

	loadFolders(page: number) {
		this.notification = { status: 2, message: '' }

		const currentDate = new Date()

		const dayString = this.padZero(currentDate.getDate())
		const monthString = this.padZero(currentDate.getMonth() + 1)
		const yearString = currentDate.getFullYear().toString()
		const dateToUse = this.searchDate ? [this.searchDate.year, this.searchDate.month, this.searchDate.day] : [yearString, monthString, dayString]

		this.dataLakeService.getFolders('apim', dateToUse, this.pageSize, this.continuationToken).subscribe({
			next: (response) => {
				console.log(response, 'response')

				// Armazena os folders em um novo índice do array
				this.folderPaths.push([response.folders])

				this.continuationToken = response.continuationToken
				this.loadingMore = false

				// Verifica se há folders no índice atual
				const currentIndex = this.folderPaths.length - 1 // Obtém o índice atual
				const currentFolders = this.folderPaths[currentIndex]

				if (currentFolders?.length) {
					const fileNames = currentFolders[0].filter((path: FilePath) => !path.isDirectory).map((path: FilePath) => path.name)
					if (fileNames.length) {
						this.loadFiles(fileNames, currentIndex) // Passa o índice atual para loadFiles
					}
				}
			},
			error: (error) => {
				console.error('Error loading folders:', error)
				this.loadingMore = false
			},
		})
	}

	loadMore() {
		if (this.continuationToken) {
			this.loadFolders(this.page + 1)
		}
	}

	loadFiles(fileNames: string[], index: number) {
		this.collectionSize = 0
		this.notification = { status: 2, message: '' }

		this.dataLakeService.getFiles('apim', fileNames).subscribe({
			next: (response) => {
				this.notification = { status: 3, message: '' }
				this.processFileDetails(response, index) // Passa o índice para processFileDetails
			},
			error: (error) => {
				console.error('Error loading files:', error)
				this.notification = { status: 4, message: 'Error loading files.' }
				this.notificationService.showMessage(error.status, error.Message, 'read', this.dynamicUrl)
			},
		})
	}

	processFileDetails(data: any, index: number): void {
		const arrayString: any = [...Object.values(data)]
		let files: any = []

		arrayString.map((item: any) => {
			return files.push(JSON.parse(item))
		})

		console.log(files, 'data')
		this.filesData[index] = files
		this.filteredFiles[index] = files
		this.collectionSize = this.filteredFiles[index].length

		//this.paginateFileApim();
	}

	previousPage() {
		if (this.currentIndex > 0) {
			this.currentIndex--
			console.log(this.currentIndex, 'index')

			const previousFolderFiles = this.filteredFiles[this.currentIndex]
			if (previousFolderFiles && previousFolderFiles.length) {
				this.loadFiles(previousFolderFiles, this.currentIndex)
				this.page = this.currentIndex + 1
			} else {
				console.log('No files available in the previous folder.')
			}
		} else {
			console.log('You are at the first folder.')
		}
	}

	nextPage() {
		this.loadMore()
		console.log(this.currentIndex, 'index')
		this.currentIndex++

		if (this.currentIndex < this.folderPaths.length) {
			const currentFolderNames = this.folderPaths[this.currentIndex].filter((path: FilePath) => !path.isDirectory).map((path: FilePath) => path.name)

			if (currentFolderNames.length) {
				this.loadFiles(currentFolderNames, this.currentIndex)
				this.page = this.currentIndex + 1
			}
		} else {
			console.log('No more folders to load.')
		}
	}

	clearFilters() {
		this.searchTerm = ''
		this.searchDate = null

		this.filteredFiles = this.filesData
		this.collectionSize = this.filteredFiles.length

		//this.paginateFileApim()
		this.cdr.detectChanges()
	}

	updateTagValueInXml(xmlString: string): string {
		const partialTagName = 'GlobalSerial' // Fragmento do nome da tag
		const newValue = 'PARGLOBAL REMOVIDO - HERMES'

		const parser = new DOMParser()
		const xmlDoc = parser.parseFromString(xmlString, 'application/xml')

		// Obter todas as tags no documento
		const allElements = xmlDoc.getElementsByTagName('*') // Pega todas as tags no XML

		// Filtrar as tags que contêm 'GlobalSerial' no nome da tag
		const matchingElements = Array.from(allElements).filter((element) => element.tagName.includes(partialTagName))

		if (matchingElements.length === 0) {
			console.warn(`Tag contendo "${partialTagName}" não encontrada no XML.`)
			return xmlString
		}

		matchingElements.forEach((element) => {
			while (element.firstChild) {
				element.removeChild(element.firstChild)
			}
			const textNode = xmlDoc.createTextNode(newValue) // Substituir pelo novo valor
			element.appendChild(textNode)
		})

		const serializer = new XMLSerializer()
		const newXmlString = serializer.serializeToString(xmlDoc)
		return newXmlString
	}

  apiNameTable() {
    
  }

	isJsonOrXml(input: string | null): 'json' | 'xml' | 'unknown' {
		if (input === null) {
			this.contentType = 'unknown'
			return 'unknown'
		}

		// Removendo espaços em branco no início e no fim da string
		const trimmedInput = input.trim()
		this.contentType = ''

		// Verificação simples para JSON
		if (trimmedInput.startsWith('{') || trimmedInput.startsWith('[')) {
			try {
				JSON.parse(trimmedInput)
				this.contentType = 'json'
				console.log(this.contentType, 'type json')
				return 'json'
			} catch (e) {
				this.contentType = 'unknown'
				return 'unknown'
			}
		}

		// Verificação simples para XML
		if (trimmedInput.startsWith('<') && trimmedInput.endsWith('>')) {
			this.contentType = 'xml'
			console.log(this.contentType, 'type xml')
			return 'xml'
		}

		this.contentType = 'unknown'
		return 'unknown'
	}

	async copyText(registro: any) {
		try {
			const text = typeof registro === 'string' ? registro : JSON.stringify(registro)
			await navigator.clipboard.writeText(text)
		} catch (error) {
			console.error('Failed to copy:', error)
		}
	}

	modalContentDetails(content: any, form: TemplateRef<any>): void {
		this.modalDetails = content
		this.openModal(form, 'xl', false)
	}

	openDetailModal(file: any, template: TemplateRef<any>) {
		this.selectedFileDetails = {
			...file,
			requestBody: {
				contentType: `${this.isJsonOrXml(file.requestBody)}`,
				content: this.isJsonOrXml(file.requestBody) === 'json' ? JSON.parse(file.requestBody) : this.updateTagValueInXml(file.requestBody),
			},
			requestBodyEndPoint: {
				contentType: `${this.isJsonOrXml(file.requestBodyEndPoint)}`,
				content:
					this.isJsonOrXml(file.requestBodyEndPoint) === 'json' ? JSON.parse(file.requestBodyEndPoint) : this.updateTagValueInXml(file.requestBodyEndPoint),
			},
			responseBody: {
				contentType: `${this.isJsonOrXml(file.responseBody)}`,
				content: this.isJsonOrXml(file.responseBody) === 'json' ? JSON.parse(file.responseBody) : this.updateTagValueInXml(file.responseBody),
			},
		}
		console.log(this.selectedFileDetails, 'modal')
		this.openModal(template, '', true)
	}

	openModal(template: TemplateRef<any>, size: string, fullscreen: boolean) {
		this.modalReference = this.modalService.open(template, { size: size, fullscreen: fullscreen, scrollable: true })

		this.modalReference.result.then(
			(result) => {
				this.modalCloseResult = `Closed with: ${result}`
			},
			(reason) => {
				this.modalCloseResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	autoDismissModal(timeout: number) {
		setTimeout(() => {
			this.modalReference.dismiss()
			this.ngOnInit()
		}, timeout)
	}

	private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'Pressed ESC to exit'
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'Clicked outside to close'
			default:
				return `Dismissed: ${reason}`
		}
	}
}
