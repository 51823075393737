
import { Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'

import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { Insights } from '../../../../models/insights.model'
import { Cadastro } from '../../../../models/cadastro.model'

import { InsightService } from '../insight.service'

import { NotificationService } from './../../../../shared/notification.service';

@Component({
	selector: 'app-generic-insight',
	templateUrl: './generic-insight.component.html',
	styleUrls: ['./generic-insight.component.scss'],
})
export class GenericInsightComponent implements OnInit {
	title: string = ''
	insightsData: Insights[] = []
	detailsData: Cadastro[] = []
	insightsCount: number = 0
	notification = { status: 0, message: '' }

	// Calendar
	hoveredDate: NgbDate | null = null
	fromDate: NgbDate | null = null
	toDate: NgbDate | null = null
	model: NgbDateStruct

	// Search
	searchTerm: string = ''
	filteredInsights: Insights[] = []
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Pagination
	page = 1
	pageSize = 100
	collectionSize: number = 0
	paginatedInsights: Insights[] = []

	// Modal
	modalRef: NgbModalRef | null = null
	closeResult: string = ''

	private queryConfig: { dateRangeQuery?: string; defaultQuery: string; title: string; detailsRoute?: string }

	constructor(
		public formatter: NgbDateParserFormatter,
		private calendar: NgbCalendar,
		private router: Router,
		private insightsService: InsightService,
    private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		const currentDate = this.calendar.getToday()
		this.model = this.calendar.getNext(currentDate, 'm', -2)
		const queryType = this.router.url.includes('failures') ? 'failures' : 'performance'
		this.queryConfig = this.insightsService.getQueryConfig(queryType)
		this.loadInsightsData()
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filteredInsights = this.searchTerm ? this.filterInsights(this.searchTerm) : this.insightsData
		this.collectionSize = this.filteredInsights.length
		this.paginateInsights()
	}

	filterInsights(term: string): Insights[] {
		const lowerCaseTerm = term.toLowerCase()
		return this.insightsData.filter((insight) => insight.operation_Name.toLowerCase().includes(lowerCaseTerm))
	}

	paginateInsights(): void {
		this.paginatedInsights = this.filteredInsights
			.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	loadInsightsData(): void {
		const hasFromDate = this.fromDate !== null
		const hasToDate = this.toDate !== null

		const formattedFromDate = hasFromDate ? `${this.fromDate!.year}-${this.fromDate!.month}-${this.fromDate!.day}T00:00:00Z` : ''
		const formattedToDate = hasToDate ? `${this.toDate!.year}-${this.toDate!.month}-${this.toDate!.day}T23:59:59Z` : ''

		let query = {}
		if (hasFromDate || hasToDate) {
			query = this.queryConfig.dateRangeQuery!
				.replace('{start_date}', formattedFromDate)
				.replace('{end_date}', formattedToDate)
		} else {
			query = this.queryConfig.defaultQuery
		}

		this.notification.status = 2

		this.insightsService.executeQuery({ query: query }).subscribe({
			next: (result) => {
				this.notification.status = 3
				this.insightsData = this.insightsService.mapInsightsDataToObjects(result.tables)
        this.filteredInsights = this.insightsData
        this.collectionSize = this.insightsData.length
        this.paginateInsights()
				this.insightsCount = result.tables.length
				this.searchTermValue
				this.fromDate = null
				this.toDate = null
			},
			error: (error) => {
        this.notificationService.showMessage(error.status, error, 'read', this.queryConfig.detailsRoute)
				console.error(error)
			},
		})
	}

	viewDetails(ids: any): void {
		this.insightsService.updateIds(ids)
		this.router.navigateByUrl(this.queryConfig.detailsRoute!)
	}

	onDateSelection(date: NgbDate): void {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date
		} else {
			this.toDate = null
			this.fromDate = date
		}
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate)
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date)
	}

	validateDateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input)
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
	}

	verifyRequiredFields(): void {
		if (!this.fromDate || !this.toDate) {
			this.requiredMessage = 'Campo obrigatório!'
		} else {
			this.loadInsightsData()
		}
	}

	clearFilters(): void {
		if (this.fromDate || this.toDate) {
			this.ngOnInit()
		} else {
			this.searchTermValue = ''
			this.fromDate = null
			this.toDate = null
			this.requiredMessage = ''
			this.filteredInsights = this.insightsData
			this.collectionSize = this.insightsData.length
			this.notification = { status: 0, message: '' }
			this.paginateInsights()
		}
	}
}
