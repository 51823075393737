import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { MsalGuard } from '@azure/msal-angular'
import { AuthGuardService } from './guard/auth.guard.service'

import { HomeComponent } from './pages/home/home.component'
import { FinanceiroComponent } from './pages/financeiro/financeiro.component'

import { CadastroComponent } from './pages/cadastro/cadastro.component'
import { PtaComponent } from './pages/pta/pta.component'
import { MetricsComponent } from './pages/monitoring/insight/metrics/metrics.component'
import { MonitoringComponent } from './pages/monitoring/monitoring.component'
import { DataLakeComponent } from './pages/monitoring/dataLake/data-lake.component'

import { SigninDataLakeComponent } from './pages/monitoring/dataLake/signin-data-lake/signin-data-lake.component'
import { OperationDetailsComponent } from './pages/monitoring/insight/details-insight/operation-details/operation-details.component'

import { ApimDataLakeComponent } from './pages/monitoring/dataLake/apim-data-lake/apim-data-lake.component'
import { InsightComponent } from './pages/monitoring/insight/insight.component'
import { GenericInsightComponent } from './pages/monitoring/insight/generic-insight/generic-insight.component'
import { DetailsInsightComponent } from './pages/monitoring/insight/details-insight/details-insight.component'
import { AlertsInsightComponent } from './pages/monitoring/insight/alerts-insight/alerts-insight.component'
import { GenericFinancialComponent } from './pages/financeiro/generic-financial/generic-financial.component'

const routes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [MsalGuard, AuthGuardService] },
	{ path: 'home', component: HomeComponent, canActivate: [MsalGuard, AuthGuardService] },
	{
		path: 'financeiro',
		component: FinanceiroComponent,
		canActivate: [MsalGuard, AuthGuardService],
		children: [
			{ path: 'bancario/retorno', component: GenericFinancialComponent },
			{ path: 'bancario/remessa', component: GenericFinancialComponent },
		],
	},
	{ path: 'pta', component: PtaComponent, canActivate: [MsalGuard, AuthGuardService] },
	{ path: 'cadastro', component: CadastroComponent, canActivate: [MsalGuard, AuthGuardService] },
	{
		path: 'monitoring',
		component: MonitoringComponent,
		canActivate: [MsalGuard, AuthGuardService],
		children: [
			{ path: 'metrics', component: MetricsComponent },
			{
				path: 'insights',
				component: InsightComponent,
				children: [
					{
						path: 'performance',
						component: GenericInsightComponent,
					},
					{
						path: 'performance/details',
						component: DetailsInsightComponent,
						children: [{ path: ':id', component: OperationDetailsComponent }],
					},
					{
						path: 'failures',
						component: GenericInsightComponent,
					},
					{
						path: 'failures/details',
						component: DetailsInsightComponent,
						children: [{ path: ':id', component: OperationDetailsComponent }],
					},
					{
						path: 'alerts',
						component: AlertsInsightComponent,
					},
				],
			},
			{
				path: 'data',
				component: DataLakeComponent,
				children: [
					{ path: 'signin', component: SigninDataLakeComponent },
					{ path: 'apim', component: ApimDataLakeComponent },
				],
			},
		],
	},
]

const isIframe = window !== window.parent && !window.opener

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
