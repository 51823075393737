<div class="card stretch-card my-3">
	<div class="card">
		<div class="card-header-custumer">
			<h5>Sucesso</h5>
		</div>
		<div echarts [options]="chartOptionSuccess" class="demo-chart"></div>
	</div>
	<div class="card">
		<div class="card-header-custumer">
			<h5>Falhas</h5>
		</div>
		<div echarts [options]="chartOptionFailures" class="demo-chart"></div>
	</div>
</div>
