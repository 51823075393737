import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { ModalDismissReasons, NgbDatepickerKeyboardService, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { FileSignIn } from '../../../../models/file-signIn.model'
import { FilePath } from '../../../../models/filepath.model'

import { DataLakeService } from '../data-lake.service'
import { DatePickerService } from '../../../../shared/date-picker.service'
import { NotificationService } from './../../../../shared/notification.service'

@Component({
	selector: 'app-signin-data-lake',
	templateUrl: './signin-data-lake.component.html',
	styleUrls: ['./signin-data-lake.component.scss'],
	providers: [{ provide: NgbDatepickerKeyboardService, useClass: DatePickerService }],
})
export class SigninDataLakeComponent implements OnInit {
	title = 'SignIn'
	dynamicUrl = 'monitoring/insights/data/signin'
	filesData: FileSignIn[] = [] // Armazena os dados iniciais
	filesDataEmail: FileSignIn[] = [] // Armazena os dados filtrados por email
	folderPaths: FilePath[] = []
	selectedFileDetails!: FileSignIn

	notification = { status: 0, message: '' }

	// Search
	searchUserEmail: string = '' // Campo de busca por email
	searchDate: any | null = null // Campo de busca por data
	searchTerm: string = '' // Campo de busca por termo
	filteredFilesSignIn: FileSignIn[] = [] // Dados filtrados para exibição
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Modal
	modalReference!: NgbModalRef
	modalCloseResult: string = ''

	// Paginação
	page = 1
	pageSize = 300
	collectionSize: number = 0
	paginatedFileSignIn: FileSignIn[] = []

	constructor(
		private dataLakeService: DataLakeService,
		private modalService: NgbModal,
		private pipe: DatePipe,
		private cdr: ChangeDetectorRef,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.loadFolders()
	}

	padZero(number: number): string {
		return number <= 9 ? '0' + number : number.toString()
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filteredFilesSignIn = this.searchTerm ? this.filterFilesSignIn(this.searchTerm) : this.filesData
		this.collectionSize = this.filteredFilesSignIn.length
		this.paginateFileSignIn()
	}

	filterFilesSignIn(term: string): FileSignIn[] {
		const lowerCaseTerm = term.toLowerCase()
		return this.filesData.filter(
			(files) =>
				files.userPrincipalName.toLowerCase().includes(lowerCaseTerm) ||
				// @ts-ignore: Object is possibly 'null'.
				this.pipe.transform(files.createdDateTime, 'dd-MM-yyyy').includes(term)
		)
	}

	paginateFileSignIn() {
		this.paginatedFileSignIn = this.filteredFilesSignIn
			.map((arquivo, i) => ({ index: i + 1, ...arquivo }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)

		this.paginatedFileSignIn = [...this.paginatedFileSignIn]

		this.cdr.detectChanges()
	}

	loadFolders() {
		this.notification = { status: 2, message: '' }
		const emailForQuery = this.searchUserEmail !== '' ? this.searchUserEmail : ''
		const currentDate = new Date()

		// Criar uma nova data para subtrair um dia
		const previousDate = new Date(currentDate)
		previousDate.setDate(currentDate.getDate() - 1)

		// Extrair partes da data anterior
		const dayString = this.padZero(previousDate.getDate())
		const monthString = this.padZero(previousDate.getMonth() + 1)
		const yearString = previousDate.getFullYear().toString()

		const dateToUse = this.searchDate ? [this.searchDate.year, this.searchDate.month, this.searchDate.day] : [yearString, monthString, dayString]

		this.dataLakeService.getFoldersSignin("insights", dateToUse).subscribe({
			next: (response) => {
				this.folderPaths = response

				if (this.folderPaths?.length) {
					const fileNames = this.folderPaths.filter((path: FilePath) => !path.isDirectory).map((path: FilePath) => path.name)

					if (fileNames.length) {
						this.loadFiles(fileNames, emailForQuery)
					}
				}
			},
			error: (error) => {
				this.notificationService.showMessage(error.status, error.Message, 'read', this.dynamicUrl)
				console.error('Error loading folders:', error)
			},
		})
	}

	loadFiles(fileNames: string[], email?: string) {
		this.collectionSize = 0
		this.notification = { status: 2, message: '' }

		this.dataLakeService.getFiles("insights", fileNames, email).subscribe({
			next: (response) => {
				this.notification = { status: 3, message: '' }

				if (email) {
					this.filesDataEmail = response
					this.filteredFilesSignIn = response
				} else {
					this.processFileDetails(response)
				}

				this.collectionSize = this.filteredFilesSignIn.length
				this.paginateFileSignIn()
			},
			error: (error) => {
				console.error('Error loading files:', error)
				this.notification = { status: 4, message: 'Error loading files.' }
				this.notificationService.showMessage(error.status, error.Message, 'read', this.dynamicUrl)
			},
		})
	}

	processFileDetails(data: any): void {
		const detailedFiles: FileSignIn[] = []

		Object.keys(data).forEach((key) => {
			const jsonArray = JSON.parse(data[key])
			jsonArray.forEach((item: FileSignIn) => {
				if (item) detailedFiles.push(item)
			})
		})

		this.filesData = detailedFiles
		this.filteredFilesSignIn = detailedFiles
	}

	clearFilters() {
		this.searchTerm = ''
		this.searchUserEmail = ''
		this.searchDate = null

		this.filteredFilesSignIn = this.filesData
		this.collectionSize = this.filteredFilesSignIn.length

		this.paginateFileSignIn()
		this.cdr.detectChanges()
	}

	openDetailModal(file: FileSignIn, template: TemplateRef<any>) {
		this.selectedFileDetails = file
		this.openModal(template, 'xl')
	}

	openModal(template: TemplateRef<any>, size: string) {
		this.modalReference = this.modalService.open(template, { fullscreen: true, scrollable: true })

		this.modalReference.result.then(
			(result) => {
				this.modalCloseResult = `Closed with: ${result}`
			},
			(reason) => {
				this.modalCloseResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	autoDismissModal(timeout: number) {
		setTimeout(() => {
			this.modalReference.dismiss()
			this.ngOnInit()
		}, timeout)
	}

	private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'Pressed ESC to exit'
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'Clicked outside to close'
			default:
				return `Dismissed: ${reason}`
		}
	}
}
