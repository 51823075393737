<div class="card m-3 stretch-card">
	<div class="card-header card-header-customer py-4 px-2 d-flex justify-content-center align-items-center">
		<form class="row row-cols-sm-auto d-flex justify-content-center align-items-center">
			<!-- <div class="col-12">
				<div class="dp-hidden position-absolute">
					<div class="input-group">
						<input
							name="datepicker"
							class="form-control"
							ngbDatepicker
							#datepicker="ngbDatepicker"
							[autoClose]="'outside'"
							(dateSelect)="onDateSelection($event)"
							[displayMonths]="2"
							navigation="none"
							[dayTemplate]="t"
							outsideDays="hidden"
							[startDate]="fromDate!"
							tabindex="-1"
							[(ngModel)]="model"
						/>
						<ng-template #t let-date let-focused="focused">
							<span
								class="custom-day"
								[class.focused]="focused"
								[class.range]="isRange(date)"
								[class.faded]="isHovered(date) || isInside(date)"
								(mouseenter)="hoveredDate = date"
								(mouseleave)="hoveredDate = null"
							>
								{{ date.day }}
							</span>
						</ng-template>
					</div>
				</div>
				<div class="col-12">
					<div class="input-group">
						<input
							#dpFromDate
							class="form-control"
							placeholder="yyyy-mm-dd"
							name="dpFromDate"
							[value]="formatter.format(fromDate)"
							(input)="fromDate = validateInput(fromDate, dpFromDate.value)"
						/>
						<button class="btn btn-input-customer" id="button-addon2" (click)="datepicker.toggle()" type="button">
							<em i-bs name="calendar-range"></em>
						</button>
					</div>
					<div class="custom-alert" role="alert" *ngIf="requiredMessage && fromDate === null" style="margin-top: 5px">
						{{ requiredMessage }}
					</div>
				</div>
			</div> -->

			<!-- <div class="col-12">
				<div class="input-group">
					<input
						#dpToDate
						class="form-control"
						placeholder="yyyy-mm-dd"
						name="dpToDate"
						[value]="formatter.format(toDate)"
						(input)="toDate = validateInput(toDate, dpToDate.value)"
					/>
					<button class="btn btn-input-customer" (click)="datepicker.toggle()" type="button">
						<em i-bs name="calendar-range"></em>
					</button>
				</div>
				<div class="custom-alert" role="alert" *ngIf="requiredMessage && toDate === null" style="margin-top: 5px">
					{{ requiredMessage }}
				</div>
			</div> -->



      <!-- <div class="form-group">
        <label class="form-control-sm text-secondary" for="operation">Operações</label>
        <div class="div-customer-empresa">
          <div class="checkbox" *ngFor="let operation of operationsFormated; let i = index">
            <label class="checkbox-inline input-check" name="empresas">
              <input type="checkbox" name="empresas" (change)="onChangeOperationName(operation.operation_Name, $event)" style="margin-right: 0.5rem" />
              {{ operation.formatted_Name }}
            </label>
          </div>
        </div>
      </div> -->

      <div class="col-12 px=0" style="width: 16rem">
				<div class="form-group">
					<select
						[ngModel]="selectedOperationName"
						class="form-control form-select border-opacity-50 p-2"
						(ngModelChange)="onChangeOperationName($event)"
						name="selectedOperationName"
						required
					>
						<option [selected]="true" disabled>Operações</option>
						<option *ngFor="let operation of operationsFormated" [value]="operation.operation">{{ operation.formatted_Name }}</option>
					</select>

				</div>
			</div>

			<div class="col-12 px=0" style="width: 16rem">
				<div class="form-group">
					<input  class="form-control" type="text" placeholder="Percentual - Ex: 10" [(ngModel)]="percentageValue" name="percentageValue"/>
				</div>
			</div>

			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="insertQueryCosmos()">
					<em i-bs name="plus" width="23" height="23"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger" (click)="cleanInputForm()">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>
	<div class="card-body px-5">Empréstimo</div>
</div>

<ng-template #modalOperationsName let-modal>
  <div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="onDismiss()"></button>
	</div>
  <div class="modal-body"></div>
</ng-template>
