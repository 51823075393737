import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'

import { ModalDismissReasons, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { Banking } from '../../../models/banking.model'

import { FinanceiroService } from './../financeiro.service'
import { NotificationService } from '../../../shared/notification.service'
import { RegistroCosmosService } from './../../../shared/registro-cosmos.service'

@Component({
	selector: 'app-generic-financial',
	templateUrl: './generic-financial.component.html',
	styleUrl: './generic-financial.component.scss',
})
export class GenericFinancialComponent implements OnInit {
	banking: Banking
	bankers: Banking[] = []

	bankersCount: number = 0

	notification = { status: 0, message: '' }
	detail = { request: null, response: null }

	// Calendar
	hoveredDate: NgbDate | null = null
	fromDate: NgbDate | null = null
	toDate: NgbDate | null = null
	model: NgbDateStruct

	// Search
	searchTerm: string = ''
	filtered: Banking[] = []
	searchFilter = new FormControl('')
	requiredMessage: string = ''

	// Pagination
	page = 1
	pageSize = 100
	collectionSize: number = 0
	paginated: Banking[] = []

	// Modal
	size: string = ''
	modalRef!: NgbModalRef
	closeResult: string = ''

	private queryConfig: { dateRangeQuery?: string; title: string; detailsRoute?: string }

	constructor(
		public formatter: NgbDateParserFormatter,
		private calendar: NgbCalendar,
		private router: Router,
		private financeiroService: FinanceiroService,
		private notificationService: NotificationService,
		private registroCosmosService: RegistroCosmosService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		const currentDate = this.calendar.getToday()
		this.model = this.calendar.getNext(currentDate, 'm', -2)
		const queryType = this.router.url.includes('retorno') ? 'retorno' : 'remessa'
		this.queryConfig = this.financeiroService.getQueryConfig(queryType)
		this.loadBankingData()
	}

	adicionaZero(numero: number) {
		if (numero <= 9) return '0' + numero
		else return numero
	}

	get searchTermValue(): string {
		return this.searchTerm
	}

	set searchTermValue(value: string) {
		this.searchTerm = value
		this.filtered = this.searchTerm ? this.filter(this.searchTerm) : this.bankers
		this.collectionSize = this.filtered.length
		this.refreshPage()
	}

	filter(term: string): Banking[] {
		const lowerCaseTerm = term.toLowerCase()
		return this.bankers.filter((bank) => {
			return bank.identify.toLowerCase().includes(lowerCaseTerm) || bank.origin.toLowerCase().includes(lowerCaseTerm)
		})
	}

	refreshPage(): void {
		this.paginated = this.filtered
			.map((bank, i) => ({ index: i + 1, ...bank }))
			.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize)
	}

	loadBankingData(): void {
		const date = new Date()
		const formattedDate = this.formatDate(date)

		const dateInitial = this.fromDate ? this.formatDate(this.fromDate, true) : `${formattedDate}T00:00:00.000Z`
		const dateEnd = this.toDate ? this.formatDate(this.toDate, false) : `${formattedDate}T23:59:59.000Z`

		const query = {
			query: this.queryConfig.dateRangeQuery!.replace('{start_date}', `'${dateInitial}'`).replace('{end_date}', `'${dateEnd}'`),
		}

		this.notification.status = 2
		this.registroCosmosService.readRegisterCosmosSDK('qa-shared', 'registros-hermes', query).subscribe({
			next: (result) => {
				if (result._count === '0') {
					this.notification = { status: 1, message: 'Nenhum dado encontrado para o dia pesquisado!' }
				} else {
					this.notification.status = 3
					this.bankers = result.Documents
					this.filtered = this.bankers
					this.collectionSize = this.bankers.length
					this.refreshPage()
					this.bankersCount = result._count
				}
			},
			error: (error) => {
				this.notificationService.showMessage(error.status, error, 'read', this.queryConfig.detailsRoute)
				console.error(error)
			},
		})
	}

	private formatDate(date: Date | { year: number; month: number; day: number }, isStart: boolean = true): string {
		if (date instanceof Date) {
			const day = this.adicionaZero(date.getDate())
			const month = this.adicionaZero(date.getMonth() + 1)
			const year = date.getFullYear()
			return `${year}-${month}-${day}`
		} else {
			const day = this.adicionaZero(date.day)
			const month = this.adicionaZero(date.month)
			const year = date.year
			return `${year}-${month}-${day}T${isStart ? '00:00:0000000Z' : '23:59:5900000Z'}`
		}
	}

	// createReturnFile(content: Banking) {
	// 	this.financeiroService.postArquivosRetorno(content).subscribe({
	// 		next: (result) => {
	// 			console.log(result, 'postRetornoBancario')
	// 			if (result.status === 200) {
	// 				if (content.origin === 'Accesstage') {
	// 					this.readUpdateStatus(content.identify)
	// 				}
	// 			}
	// 		},
	// 		error: (error) => {
	// 			console.error(error, 'postRetornoBancario')
	// 		},
	// 	})
	// }

	// readUpdateStatus(id: string) {
	// 	const body = { ConfirmacaoRetiradaRequest: { trackingID: `${id}`, nmeArquivo: '' } }
	// 	this.financeiroService.postAtualizaStatusArquivo(body).subscribe({
	// 		next: (result) => {
	// 			console.log(result, 'atualiza status')
	// 		},
	// 		error: (error) => {
	// 			console.error(error, 'atualiza status')
	// 		},
	// 	})
	// }

	// toRemoveQueue(content: Banking) {
	//   this.readUpdateStatus(content.id)

	// }

	// reprecessModal(content: Banking, form: TemplateRef<any>) {
	// 	this.openModal(form, (this.size = 'xl'))
	// 	this.createReturnFile(content)
	// }

	detailModal(docBank: Banking, form: TemplateRef<any>) {
		this.detail.request = docBank.request == null ? {} : JSON.parse(docBank.request)
		this.detail.response = docBank?.response == null ? {} : JSON.parse(docBank?.response)
		this.openModal(form, (this.size = 'xl'))
	}

	onDateSelection(date: NgbDate): void {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date
		} else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date
		} else {
			this.toDate = null
			this.fromDate = date
		}
	}

	isHovered(date: NgbDate) {
		return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate)
	}

	isRange(date: NgbDate) {
		return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date)
	}

	validateDateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
		const parsed = this.formatter.parse(input)
		return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue
	}

	verifyRequiredFields(): void {
		if (!this.fromDate || !this.toDate) {
			this.requiredMessage = 'Campo obrigatório!'
		} else {
			this.loadBankingData()
		}
	}

	clearFilters(): void {
		this.fromDate = null
		this.toDate = null
		this.searchTermValue = ''
		this.ngOnInit()
	}

	openModal(form: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.open(form, { size, scrollable: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	onDismiss(timeout: number) {
		setTimeout(() => {
			this.modalRef.dismiss()
		}, timeout)
		this.clearFilters()
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
