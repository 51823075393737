import { Component, Inject, OnInit } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  loginDisplay = false
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService
  ) {}

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  async logIn() {
		if (this.msalGuardConfig.authRequest) {
			this.msalService.loginRedirect({
				...this.msalGuardConfig.authRequest,
			} as RedirectRequest)
		} else {
			this.msalService.loginRedirect()
		}
	}

  setLoginDisplay() {
		this.loginDisplay = this.msalService.instance.getAllAccounts() ? true : false
	}

	ngOnDestroy(): void {
		this._destroying$.next(undefined)
		this._destroying$.complete()
	}
}
