<div class="card stretch-card">
	<div class="card-header card-header-customer pb-2">
		<form class="row row-cols-sm-auto justify-content-center align-items-center mb-3">
			<div class="col-12">
				<div class="input-group">
					<input class="form-control" placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="searchDate" ngbDatepicker #d="ngbDatepicker" />
					<button class="btn btn-input-customer" id="button-addon2" (click)="d.toggle()" type="button">
						<em i-bs name="calendar-range"></em>
					</button>
				</div>
			</div>

			<!-- Search Input -->
			<!-- <div class="input-group search w-25">
				<input class="form-control" type="text" placeholder="Pesquisar na página" [(ngModel)]="searchTermValue" name="searchTerm" />
			</div> -->

			<div class="col-12 d-flex align-items-center">
				<button type="button" class="btn btn-log-customer btn-customer-info" (click)="loadFolders(page)">
					<em i-bs name="search"></em>
				</button>
				<button type="button" class="btn btn-log-customer btn-customer-danger" (click)="clearFilters()">
					<em i-bs name="x" width="23" height="23"></em>
				</button>
			</div>
		</form>
	</div>

	<div *ngIf="notification.status === 2" class="d-flex justify-content-center align-items-center p-5" style="height: 65vh">
		<div class="spinner-border text-primary" role="status"></div>
	</div>

	<table *ngIf="notification.status === 3" class="table table-sm table-hover align-middle">
		<caption style="display: none">
			Tabela mostra informações referente ao dataLake Signin
		</caption>
		<thead class="table-light">
			<tr>
				<th scope="col" class="text-center align-middle">Operação</th>
        <th scope="col" class="text-center align-middle">API</th>
				<th scope="col" class="text-center align-middle">Data</th>
				<th scope="col" class="text-center align-middle">Produto</th>
				<th scope="col" class="text-center align-middle">Método</th>
				<th scope="col" class="text-center align-middle">Status Code</th>
				<th scope="col" class="text-center align-middle" style="width: 6rem">Detalhes</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let file of filteredFiles[currentIndex]">
				<td class="align-middle">{{ file.operationName }}</td>
        <td class="align-middle">{{ file.apiName }}</td>
				<td class="text-center align-middle">{{ file.dataHora | date : 'dd-MM-yyyy hh:mm:ss' }}</td>
				<td class="text-center align-middle">{{ file.productName }}</td>
				<td class="text-center align-middle">{{ file.method }}</td>
				<td class="text-center align-middle">{{ file.responseCode }}</td>
				<td class="text-center align-middle" style="width: 6rem">
					<button type="button" class="btn btn-customer-info" (click)="openDetailModal(file, modalDetail)">
						<em i-bs name="justify" width="20" height="20"></em>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="card-footer-customer py-4 px-0 d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-center align-items-center">
      <button class="btn btn-log-customer btn-customer-info" type="button" (click)="previousPage()">
        <em i-bs name="chevron-double-left" width="12" height="12"></em>
      </button>
      <div class="p-1">Página: {{ page }} de {{ folderPaths.length }}</div>
      <button class="btn btn-log-customer btn-customer-info" type="button" (click)="nextPage()">
        <em i-bs name="chevron-double-right" width="12" height="12"></em>
      </button>
    </div>

	</div>
</div>

<ng-template #modalDetail let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div class="section">
			<div class="property">
				<strong>Id:</strong>
				<p class="value">{{ selectedFileDetails.operationId }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Request Id:</strong>
				<p class="value">{{ selectedFileDetails.requestId }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Operation Name:</strong>
				<p class="value">{{ selectedFileDetails.operationName }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Product Name:</strong>
				<p class="value">{{ selectedFileDetails.productName }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Subscription Key:</strong>
				<p class="value">{{ selectedFileDetails.subscriptionKey }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Subscription Name:</strong>
				<p class="value">{{ selectedFileDetails.subscriptionName }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Request Ip:</strong>
				<p class="value">{{ selectedFileDetails.requestIp }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Data Hora:</strong>
				<p class="value">{{ selectedFileDetails.dataHora | date : 'dd-MM-yyyy hh:mm:ss' }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Operation URL Template:</strong>
				<p class="value">{{ selectedFileDetails.operationURLTemplate }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Duration:</strong>
				<p class="value">{{ selectedFileDetails.duration }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Api Name:</strong>
				<p class="value">{{ selectedFileDetails.apiName }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Method:</strong>
				<p class="value">{{ selectedFileDetails.method }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Original URL:</strong>
				<p class="value">{{ selectedFileDetails.OriginalURL }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>URL:</strong>
				<p class="value">{{ selectedFileDetails.URL }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Host:</strong>
				<p class="value">{{ selectedFileDetails.host }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Request Body:</strong>
				<div class="section d-flex justify-content-between align-items-start" style="max-height: 20rem; overflow: hidden">
					<div style="width: 58vw" *ngIf="selectedFileDetails.requestBody.contentType === 'json'" [innerHTML]="selectedFileDetails.requestBody.content | highlightJson"></div>
					<div style="width: 58vw" *ngIf="selectedFileDetails.requestBody.contentType === 'xml'" [innerHTML]="selectedFileDetails.requestBody.content | highlightXml"></div>
					<div *ngIf="selectedFileDetails.requestBody.contentType != 'unknown'" class="d-flex justify-content-between align-items-start">
						<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Copiar" (click)="copyText(selectedFileDetails.requestBody.content)">
							<em i-bs name="files" width="20" height="20" class="px-1"> </em>
						</button>
						<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Detalhes" (click)="modalContentDetails(selectedFileDetails.requestBody, modalDetailRequestResponse)">
							<em i-bs name="three-dots" width="20" height="20" class="px-1"> </em>
						</button>
					</div>
				</div>
			</div>
			<hr />
			<div class="property">
				<strong>Request Body EndPoint:</strong>
				<div class="section d-flex justify-content-between align-items-start" style="max-height: 20rem; overflow: hidden">
					<div style="width: 58vw" *ngIf="selectedFileDetails.requestBodyEndPoint.contentType === 'json'" [innerHTML]="selectedFileDetails.requestBodyEndPoint.content | highlightJson"></div>
					<div style="width: 58vw" *ngIf="selectedFileDetails.requestBodyEndPoint.contentType === 'xml'" [innerHTML]="selectedFileDetails.requestBodyEndPoint.content | highlightXml"></div>
					<div *ngIf="selectedFileDetails.requestBodyEndPoint.contentType != 'unknown'" class="d-flex justify-content-between align-items-start">
						<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Copiar" (click)="copyText(selectedFileDetails.requestBodyEndPoint.content)">
							<em i-bs name="files" width="20" height="20" class="px-1"> </em>
						</button>
						<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Detalhes" (click)="modalContentDetails(selectedFileDetails.requestBodyEndPoint, modalDetailRequestResponse)">
							<em i-bs name="three-dots" width="20" height="20" class="px-1"> </em>
						</button>
					</div>
				</div>
			</div>
			<hr />
			<div class="property">
				<strong>Response Body:</strong>
				<div class="section d-flex justify-content-between align-items-start" style="max-height: 20rem; overflow: hidden">
					<div style="width: 58vw" *ngIf="selectedFileDetails.responseBody.contentType === 'json'" [innerHTML]="selectedFileDetails.responseBody.content | highlightJson"></div>
					<div style="width: 58vw" *ngIf="selectedFileDetails.responseBody.contentType === 'xml'" [innerHTML]="selectedFileDetails.responseBody.content | highlightXml"></div>
					<div *ngIf="selectedFileDetails.responseBody.contentType != 'unknown'" class="d-flex justify-content-between align-items-start">
						<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Copiar" (click)="copyText(selectedFileDetails.responseBody.content)">
							<em i-bs name="files" width="20" height="20" class="px-1"> </em>
						</button>
						<button class="btn btn-log-customer btn-customer-info" ngbTooltip="Detalhes" (click)="modalContentDetails(selectedFileDetails.responseBody, modalDetailRequestResponse)">
							<em i-bs name="three-dots" width="20" height="20" class="px-1"> </em>
						</button>
					</div>
				</div>
			</div>
			<hr />
			<div class="property">
				<strong>Response Code:</strong>
				<p class="value">{{ selectedFileDetails.responseCode }}</p>
			</div>
			<hr />
			<div class="property">
				<strong>Response Reason:</strong>
				<p class="value">{{ selectedFileDetails.responseReason }}</p>
			</div>
			<hr />
		</div>
	</div>
</ng-template>
<ng-template #modalDetailRequestResponse let-modal>
	<div class="modal-header">
		<h4 class="modal-title text-primary"><strong>Detalhes</strong></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<div *ngIf="modalDetails.contentType === 'json'" [innerHTML]="modalDetails.content | highlightJson"></div>
		<div *ngIf="modalDetails.contentType === 'xml'" [innerHTML]="modalDetails.content | highlightXml"></div>
	</div>
</ng-template>
