import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { CustomDimensions, CustomMeasurements, OperationDetails } from '../../../../../models/insights.model'

import { InsightService } from '../../insight.service'

@Component({
	selector: 'app-operation-details',
	templateUrl: './operation-details.component.html',
	styleUrl: './operation-details.component.scss',
})
export class OperationDetailsComponent implements OnInit {
	@ViewChild('codeBlockRequest', { static: false }) codeBlockRequest: ElementRef | undefined
	@ViewChild('codeBlockResponse', { static: false }) codeBlockResponse: ElementRef | undefined

	operationDetails: OperationDetails
	customerDimensions: CustomDimensions | null = null
	customMeasurements: CustomMeasurements | null = null

	requestXML: any
	requestJson: any
	responseXML: any
	responseJson: any
	contentType: string

	// modal
	size: string = ''
	modalRef!: NgbModalRef
	closeResult: string = ''
	modalDetails: any

	constructor(private insightService: InsightService, private modalService: NgbModal) {}

	async ngOnInit() {
		await this.insightService.data$.subscribe((data) => {
			this.operationDetails = data
			this.getPropertyNameCustomDimensions(this.operationDetails.customDimensions)
			this.getPropertyCustomMeasurements(this.operationDetails.customMeasurements)
		})
	}

	getPropertyNameCustomDimensions(dimensions: any) {
		if (dimensions) {
			let customer
			try {
				customer = JSON.parse(dimensions)
				if (typeof customer === 'object') {
					this.customerDimensions = {
						APIName: customer['API Name'] ?? null,
						APIRevision: customer['API Revision'] ?? null,
						APIType: customer['API Type'] ?? null,
						Cache: customer['Cache'] ?? null,
						HTTPMethod: customer['HTTP Method'] ?? null,
						OperationName: customer['Operation Name'] ?? null,
						ProductName: customer['Product Name'] ?? null,
						Region: customer['Region'] ?? null,
						RequestId: customer['Request Id'] ?? null,
						RequestBody: this.isJsonOrXml(customer['Request-Body']) === 'json' ? JSON.parse(customer['Request-Body']) : customer['Request-Body'],
						RequestSESSIONID: customer['Request-SESSIONID'] ?? null,
						ResponseBody: this.isJsonOrXml(customer['Response-Body']) === 'json' ? JSON.parse(customer['Response-Body']) : customer['Response-Body'],
						ServiceID: customer['Service ID'] ?? null,
						ServiceName: customer['Service Name'] ?? null,
						ServiceType: customer['Service Type'] ?? null,
						SubscriptionName: customer['Subscription Name'] ?? null,
					}
					console.log(this.customerDimensions, 'customer depois')
				} else {
					console.error('O valor é undefined')
				}
			} catch (error) {
				if (error instanceof Error) {
					console.error('Erro ao parsear JSON:', error.message)
					this.customerDimensions = {}
				} else {
					console.error('Erro desconhecido ao parsear JSON:', error)
				}
				console.error('JSON problemático:', dimensions)
			}
		}
	}

	isJsonOrXml(input: string): 'json' | 'xml' | 'unknown' {
		console.log(input, 'verificacao')
		// Removendo espaços em branco no início e no fim da string
		const trimmedInput = input.trim()
		this.contentType = ''

		// Verificação simples para JSON
		if (trimmedInput.startsWith('{') || trimmedInput.startsWith('[')) {
			try {
				// Tentativa de fazer parse para verificar se é um JSON válido
				JSON.parse(trimmedInput)
				this.contentType = 'json'
				console.log(this.contentType, 'type json')
				return 'json'
			} catch (e) {
				this.contentType = 'unknown'
				return 'unknown' // Não é um JSON válido
			}
		}

		// Verificação simples para XML
		if (trimmedInput.startsWith('<') && trimmedInput.endsWith('>')) {
			this.contentType = 'xml'
			console.log(this.contentType, 'type xml')
			return 'xml'
		}
		this.contentType = 'unknown'
		return 'unknown'
	}

	getPropertyCustomMeasurements(measurements: any) {
		let customer
		try {
			customer = JSON.parse(measurements)
			this.customMeasurements = {
				ClientTime: customer['Client Time (in ms)'] ?? null,
				RequestSize: customer['Request Size'] ?? null,
				ResponseSize: customer['Response Size'] ?? null,
			}
		} catch (error) {
			console.error('Erro ao parsear JSON para custom measurements:', error)
		}
	}

	async copyText(registro: any) {
		try {
			const text = typeof registro === 'string' ? registro : JSON.stringify(registro)
			await navigator.clipboard.writeText(text)
		} catch (error) {
			console.error('Failed to copy:', error)
		}
	}

	modalContentDetails(content: any, form: TemplateRef<any>): void {
		this.contentType = ''

		const stringContent = typeof content === 'string' ? content.trim() : JSON.stringify(content)

		this.contentType = this.isJsonOrXml(stringContent)

		this.modalDetails = content

		this.openModal(form, (this.size = 'xl'))
	}

	openModal(form: TemplateRef<any>, size: string) {
		this.modalRef = this.modalService.open(form, { size, scrollable: true })

		this.modalRef.result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`
			}
		)
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'Pressione ESC para sair'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'Click OK para executar a tarefa'
		} else {
			return `with: ${reason}`
		}
	}
}
